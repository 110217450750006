/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const pt = {
  app: {
    title: 'Segmentação de base - Revendedores (REs)',
  },

  datepicker: {
    selectDate: (hasStartValue: boolean, isRange: boolean) =>
      `Selecione uma data ${isRange && hasStartValue ? 'final' : 'inicial'}`,
  },

  mission: {
    title: 'Recomendado para o ciclo',
    ariaLabel: 'Ícone de alvo para recomendação de ciclo',
  },

  resellerList: {
    title: (total: number) => `Total de ${total} revendedores`,
    detailReName: 'Nome de registro',
    detailReCode: 'Código',
    detailReCycle: 'Ciclo em atividade',
    detailOpeningReCycle: 'Ciclo em atividade na abertura',
    detailReCredit: 'Crédito liberado com tolerância',
    detailReCreditTooltip:
      'Crédito que o revendedor (RE) pode usar para comprar. É a soma do crédito liberado com a tolerância de crédito concedida para o revendedor.',
    detailReTabulation: 'Última tabulação',
    detailReAmphora: 'Ânfora dourada',
    detailTitle: 'Informações complementares',
    detailOpenAttendance: 'Iniciar atendimento',
    withoutSectorsTitle: 'Sem estrutura comercial',
    detailFidelityTitle: 'Fidelidade',
    detailFidelityMcmTitle: 'Faixa atual do meu clube multi',
    detailDefaulterTitle: 'Adimplência',
    currentCycle: 'Ciclo Atual',
    currentCycleTooltip:
      'Ciclo mais atualizado do revendedor. Todos os dados do revendendor são relacionados ao seu respectivo ciclo atual.',
    detailDefaulterAnswer: {
      yes: 'Inadimplente',
      no: 'Adimplente',
    },
    detailLastTabulation: 'Último atendimento',
    withoutSectorsDescription:
      'Você não possui nenhuma estrutura comercial cadastrada. Entre em contato com sua pessoa supervisora na operação para cadastrar sua estrutura comercial.',
    columns: {
      id: 'Código RE',
      name: 'Nome',
      creditBalance: 'Crédito liberado',
      nextRangeValue: 'Valor próxima faixa',
      phone: 'Telefone',
      inactiveLevel: 'Nível de inatividade',
      currentCycle: 'Ciclo Atual',
      status: 'Status de bloqueio',
      statusBlocked: 'Bloqueado',
      statusUnblocked: 'Sem bloqueio',
      lastAttendance: 'Último atendimento',
    },
    mld: {
      title: 'Minha Loja Digital',
      withoutRegistration: 'Revendedor sem cadastro',
    },
  },

  promotions: {
    title: 'Oferta Exclusiva - Alerta Multi',
    notEnoughData: 'Ainda não há produtos para recomendar',
    noExclusivePromotionsAvailable: 'Não há Oferta Exclusiva - Alerta Multi disponível',
    campaigns: {
      campaignsBot: 'O Boticário',
      campaignsOui: 'O.U.i',
      campaignsEud: 'Eudora',
      campaignsQdb: 'Quem disse, Berenice?',
      campaignsMm: 'Multimarcas',
    },
  },

  sectorsFilter: {
    ariaLabel: 'Abas de responsáveis, corresponsáveis e todos',
    all: 'Todos',
    responsible: 'Responsável',
    coresponsible: 'Corresponsável',
    activeSectors: 'Setores:',
    manageSectors: 'Gerenciar setores',
    manageSectorsActive: 'Gerenciar setores ativos',
    cancelEdition: 'Cancelar',
    confirmEdition: 'Confirmar setores ativos',
    sectorCode: 'Código do setor',
    sectorName: 'Nome do setor',
    responsibility: 'Responsabilidade',
    selectUnselectAll: 'Selecionar/deselecionar todos',
    RESPONSIBLE: 'Responsável',
    CORRESPONSIBLE: 'Corresponsável',
    noSectorsSelected: 'Nenhum setor selecionado!\n Selecione ao menos um setor para continuar.',
  },

  copyButton: {
    copyContent: 'Copiar conteúdo',
  },

  listTable: {
    heading: (total: number) => `${total} revendedores encontrados`,
    subtitle: (date: string, hour: string) => `Última atualização em ${date} às ${hour}`,

    loadingState: {
      title: 'Carregando as informações',
      description: 'Por favor, aguarde um instante.',
    },
  },

  generalDataInformations: {
    status: {
      notInStructure: 'Revendedor não faz parte da sua estrutura.',
      withoutStructure:
        'Revendedor não faz parte de nenhuma estrutura. Entre em contato com a gerente da operação.',
      inAttendance: 'Revendedor já está em atendimento com outra Supervisora.',
      blocked: 'Revendedor bloqueado',
      harvest: 'Revendedor alocado no Berçário - Safra {{harvest}}',
    },
  },

  notFoundState: {
    defaultMessage: 'Nenhuma informação encontrada.',
  },

  common: {
    errorState: {
      title: 'Não foi possível carregar os dados',
      description: 'Por favor, tente novamente.',
      buttonText: 'Tentar novamente',
    },
    yes: 'Sim',
    no: 'Não',
  },

  objectivesFilter: {
    drawer: {
      headerText: 'Filtros',
    },
    clearBtn: {
      label: 'Limpar itens selecionados',
      ariaLabel: 'Botão para limpar todos os filtros',
    },
    applyBtn: {
      label: 'Aplicar filtro',
      ariaLabel: 'Botão para aplicar os filtros',
    },
  },

  shoppingHistory: {
    title: 'Informações exclusivas do revendedor',
    subtitle: 'Histórico de compras',
    notEnoughData: 'Não há dados suficientes sobre o histórico de compras',
    topPurcheasedProducts: 'Produtos mais comprados',
  },

  tableCellButton: {
    button: {
      ariaLabel: 'Abrir detalhes do revendedor',
    },
  },

  dataTable: {
    checkboxRecordsLabel: 'Selecionar todos os registros',
    checkboxRecordLabel: 'Selecionar registro',
    filter: 'Filtros',
    clearFilters: 'Remover filtros',
    appliedFilters: 'Filtros aplicados',
    removeFilter: (label: string, value: string) => `Remover filtro ${label}:${value}`,

    extendedOrdering: (start: string, end: string) => `Em captação estendida de ${start} a ${end}`,
  },

  actions: {
    checkedRecords: (count: number) =>
      count > 1 ? `${count} revendedores selecionados` : `${count} revendedor selecionado`,
    copyRecords: 'Copiar itens selecionados',
    copyAllRecords: (total: number) => `Copiar todos os ${total} revendedores`,
    ariaLabelCopyRecords: 'Botão para copiar itens selecionados',
  },

  filtersProvider: {
    group: {
      triggers: 'Acionamentos',
      activity: 'Atividade',
      region: 'Região',
      attendance: 'Atendimento',
      fidelity: 'Fidelidade',
      financial: 'Financeiro',
      exclusivities: 'Exclusividades',
      shoppingHabits: 'Hábitos de Compra',
      mission: 'Estratégia',
    },
    field: {
      creditMin: {
        label: 'Crédito mínimo liberado',
        filter: 'Crédito mínimo liberado',
      },
      creditMax: {
        label: 'Crédito máximo liberado',
        filter: 'Crédito máximo liberado',
      },
      mission: {
        label: 'Missão',
        filter: 'Missão',
      },
      lastActivationPeriod: {
        label: 'Período do último acionamento',
        filter: 'Período do último acionamento',
        init: 'Período inicial',
        end: 'Período final',
      },
      noTriggeringInTheCycle: {
        label: 'Nenhum acionamento no ciclo',
        filter: 'Nenhum acionamento no ciclo',
      },
      currentCycle: {
        label: 'Ciclo atual',
        filter: 'Ciclo atual',
      },
      negotiationTitle: {
        label: 'Em negociação',
        filter: 'Em negociação',
      },
      promotions: {
        label: 'Oferta Exclusiva - Alerta Multi',
        filter: 'Oferta Exclusiva - Alerta Multi',
      },
      increaseCycleLimit: {
        label: 'Aumento de crédito no ciclo',
        filter: 'Aumento de crédito no ciclo',
      },
      fidelity: {
        label: 'Faixa de fidelidade',
        filter: 'Faixa de fidelidade',
      },
      nextRangeValue: {
        label: 'Valor para subir de faixa fidelidade',
        filter: 'Valor para subir de faixa fidelidade',
      },
      nextRangeMin: {
        label: 'Valor mínimo',
        filter: 'Valor mínimo',
      },
      nextRangeMax: {
        label: 'Valor máximo',
        filter: 'Valor máximo',
      },
      newTag: {
        label: 'Novo!',
      },
      anyAttendanceCycle: {
        label: 'Nenhum atendimento no ciclo',
        filter: 'Atendimento no ciclo',
      },
      tabulation: {
        label: 'Período do último atendimento',
        filterInit: 'Atendimento início',
        filterEnd: 'Atendimento fim',
      },
      inactivityLevel: {
        label: 'Nível de inatividade',
        filter: 'Atividade',
      },
      birthdate: {
        label: 'Período do aniversário',
        filterInit: 'Aniversário início',
        filterEnd: 'Aniversário fim',
      },
      blocked: {
        label: 'Status de bloqueio',
        filter: 'Bloqueio',
        options: {
          blocked: 'Bloqueado',
          nonBlocked: 'Sem bloqueio',
        },
      },
      city: {
        label: 'Cidade',
        filter: 'Cidade',
      },
      cs: {
        label: 'Loja (CS)',
        filter: 'CS',
      },
      zoneer: {
        label: 'Zona do ER',
        filter: 'ER',
      },
      activatedERCycle: {
        label: 'Comprou no ER no ciclo',
        filter: 'Comprou no ER no ciclo',
      },
      boughtMultiBrand: {
        label: 'Comprou multimarcas no ciclo',
        filter: 'Comprou multimarcas no ciclo',
      },
      brandsBought: {
        label: 'Marcas compradas nos últimos 6 ciclos',
        filter: 'Marcas compradas',
      },
      appAdhesion: {
        label: 'Adesão ao App da RE',
        filter: 'Adesão ao App da RE',
      },
      harvest: {
        label: 'Ciclo de entrada (Safra)',
        filter: 'Ciclo de entrada',
      },
      reason: {
        label: 'Motivo do atendimento',
        filter: 'Motivo',
      },
      subreason: {
        label: 'Submotivo do atendimento',
        filter: 'Submotivo',
      },
    },
  },

  activationRegister: {
    copyStep: {
      description: 'Escolha as informações dos revendedores',
      bodyTitle: 'Quais dados do revendedor você deseja copiar?',
      resellerCode: 'Código RE',
      name: 'Nome',
      phone: 'Telefone',
      availableCredit: 'Crédito liberado',
      nextFidelityTierValue: 'Valor para subir de faixa fidelidade',
      cancel: 'Cancelar',
      copy: 'Copiar',
    },

    dispatchStep: {
      register: 'Após concluir o envio, retorne e registre os acionamentos',
      description: 'Colar códigos e nomes e enviar acionamentos',
      registerSuccess: 'Registrar acionamento',
      registerFailed: 'Não consegui realizar o envio',
    },

    registerStep: {
      description: 'Registrar os acionamentos',
    },

    registerStepError: {
      fieldLabel: 'Por favor, conte o motivo do envio não ter sido realizado:',
      description: 'Informar o motivo do envio não realizado',
      minChar: 'Escreva uma descrição com no mínimo 15 e no máximo 300  caracteres',
      fieldPlaceholder: 'Descreva o que aconteceu:',
    },

    steps: {
      current: 'Em andamento',
      error: 'Falha',
      await: 'Aguardando',
      done: 'Concluído',
    },
  },

  registerModal: {
    sendActivation: 'Envio dos acionamentos',
    back: 'Voltar',
    continue: 'Continuar',
    register: 'Registrar',
    registerSuccess: 'Registro realizado com sucesso',
    registerFailed: 'Não foi possível realizer o registro. Por favor tente novamente.',
    channelLabel: 'Qual foi o canal de acionamento?',
    reasonLabel: 'Qual foi o motivo dos acionamentos?',
    channels: {
      whatsapp: 'WhatsApp',
      phone: 'Telefone',
      email: 'E-mail',
    },
    observationLabel: 'Observação:',
  },

  tablePagination: {
    showingRecords: (pageTotal: number, total: number) =>
      `Exibindo ${pageTotal} de ${total} resultados`,
    dropdownLabel: 'Quantidade de linhas',
  },
}

export { pt }
