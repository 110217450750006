import { FloraButton as Button, FloraTypography as Typography } from '@grupoboticario/flora-react'
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons'

import { useEvents } from '@shared/events'
import { pt } from '@shared/locales'

import { useFiltersContext } from '@shared/providers'
import { useTableBodyContext, useTableContext } from '../table-provider'
import { ActionsContainer } from './actions.styles'

type ActionProps<T> = { allRecords: T[] }

const Actions = <T,>(props: ActionProps<T>): JSX.Element | null => {
  const events = useEvents()
  const filterContext = useFiltersContext()
  const { recordsChecked, setRecordsChecked } = useTableBodyContext<T>()
  const { actions, onCopyRecords } = useTableContext<T>()

  const doCopy = (records: T[]) => {
    actions.onCopyData?.(records)
    setRecordsChecked([])
  }

  const copyRecords = (): void => {
    onCopyRecords?.()
    doCopy(recordsChecked)
  }

  const copyAllRecords = (): void => {
    onCopyRecords?.()
    if (props.allRecords.length) {
      doCopy(props.allRecords)
    }
  }

  if (!recordsChecked.length) {
    return null
  }

  const onClickButton = (): void => {
    copyRecords()
    events.clickCopyRecordsOpenModal()
  }

  const onClickCopyAll = (): void => {
    copyAllRecords()
    events.clickCopyAllRecordsOpenModal()
  }

  return (
    <ActionsContainer>
      <Typography fontSize="exceptionsAuxiliar" color="$nonInteractiveAuxiliar" fontWeight="medium">
        {pt.actions.checkedRecords(recordsChecked.length)}
      </Typography>

      <Button
        onClick={onClickButton}
        hierarchy="secondary"
        size="small"
        has="iconLeft"
        icon={<TwoPiledSquaresIcon />}
        aria-label={pt.actions.ariaLabelCopyRecords}
      >
        {pt.actions.copyRecords}
      </Button>
      <Button
        onClick={onClickCopyAll}
        hierarchy="secondary"
        size="small"
        has="iconLeft"
        aria-label={pt.actions.ariaLabelCopyRecords}
      >
        {pt.actions.copyAllRecords(filterContext.pagination.totalItems)}
      </Button>
    </ActionsContainer>
  )
}

export { Actions }
